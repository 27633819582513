import React,{useState,useEffect,Fragment} from 'react'
import {Link,useHistory, Redirect} from "react-router-dom";
import Axios from 'axios'
import moment from 'moment'
import Linkify from 'react-linkify';


const Tutorial = () => {
    const [tutorialData, setTutorialData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const lsData = !!localStorage.getItem("userData")

    
    useEffect(async() => {       
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + '/api/tutorial/alltutorial', {withCredentials:true})
        setTutorialData(response.data.tutorial)
        console.log(response.data.tutorial)
        setCategoryData(response.data.category)
    },[])

    const handleChange = async(e) =>{
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + '/api/tutorial/filtertutorial',{id:e.target.value}, {withCredentials:true})
        setTutorialData(response.data.tutorial)
        setCategoryData(response.data.category)
   }

   
   if(!lsData){
    return (<Redirect to='/signin' />) 
    }
    
    return (
        <Fragment>
           <div className="container-fluid"  style={{marginRight:"20px"}}>
                <div className="row" style={{marginRight:"20px"}}>
                    <div  className="col-sm-6">
                    <div className="form-group">
                                <select id="categoryid" className="form-control" name="category" onChange={handleChange}>
                                 <option value={'5fef2a39f73e6effffffffff'}>Please Select One</option>
                                    {categoryData.map(option => (
                                        <option value={option._id}>{option.title}</option>
                                    )) }
                                </select>
                    </div>
                    </div>
                     <div className="col-sm-6">
                     <Link to="/addtutorial" className="btn btn-primary" style={{float:"right"}}><i className="material-icons">&#xE147;</i><span> Add New Tutorial</span></Link>
                    </div>
                </div>
                <br/>
             <table className="table table-striped table-hover">
                <thead>
                <tr className="bg-info text-white">
                    <th>Title</th>
                    <th>Description</th>
                    <th>Category</th>
                    <th>Date Posted</th>
                    <th>Edit</th>
                </tr>
                </thead>
                <tbody>
                 {tutorialData.map(record => {
                    return (<tr key={record._id}>
                     <td style={{width:"25%"}}>{record.title}</td>
                     <td style={{width:"45%"}}><Linkify properties={{target:'_blank'}}>{record.bodytext}</Linkify></td>
                     <td style={{width:"15%"}}>{record.category.title}</td>
                     <td style={{width:"10%"}}>{moment(record.date).format('MMMM DD, YYYY')}</td>
                     <td style={{width:"5%"}}><Link to={`/edittutorial/${record._id}`} className="edit"  data-toggle="modal"><i className="material-icons" title="Edit">&#xE254;</i></Link></td>
                   </tr>
                    )
                 })                    
                 }   
                </tbody>
            </table>
        </div>

        </Fragment>
    )
}

export default Tutorial
