import React,{useState,useEffect,Fragment} from 'react'
import Axios from 'axios'
import {Redirect,useParams} from "react-router-dom";
import DOMPurify from 'dompurify';


const ViewCodeSnippet = () => {
    const [blogData, setBlogData] = useState([])
    const {id} = useParams()
    const lsData = !!localStorage.getItem("userData")
    const sanitizer = DOMPurify.sanitize;

    useEffect(async() => {
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/blog/viewblog/${id}`, {withCredentials:true})
        setBlogData(response.data.blog)
    }, [])

    if(!lsData){
        return (<Redirect to='/signin' />) 
        }
    

    return (
        <div>
          <article>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-10 col-md-10 mx-auto">
                        <h1>{blogData.title}</h1>
                        <article style={{marginTop:"2.5rem"}} dangerouslySetInnerHTML={{__html: sanitizer(blogData.bodytext)}}/>
                    </div>
            </div>
            </div>
           </article>
        </div>
    )
}

export default ViewCodeSnippet
