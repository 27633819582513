import {Link,useHistory, Redirect} from "react-router-dom";
import {useContext,useState,useEffect} from 'react'
import {UserContext} from './userContext'
import Cookies from 'universal-cookie';

const Navbar = () => {
    const userDataContext = useContext(UserContext)
    const[render, setRender] = useState(false)
    const history = useHistory()
    const lsData = JSON.parse(localStorage.getItem("userData"))
    const cookies = new Cookies();

    useEffect(() => {       
        if(lsData !== null){
            userDataContext.setDisplayName(lsData ? lsData.admin.displayname : "")
            userDataContext.setUserEmail(lsData ? lsData.admin.emailacct : "")
            userDataContext.setProfileImage(lsData ? lsData.admin.profileimage : "")
            }
    }, [])
   
    if(!lsData){
        return (<Redirect to='/signin' />) 
    }
 

    if(cookies.get('tokenID') === null || cookies.get('tokenID')===undefined) {
        localStorage.removeItem('userData')    
       }
 
    const renderList =()=>{    
          if(lsData !== null ){
            return [
             <li className="nav-item active">
               <Link className="nav-link" to="/dashboard" style={{color:"white"}}>Dashboard<span className="sr-only">(current)</span></Link>
            </li>,
            <li className="nav-item">
                <Link className="nav-link" to="/category" style={{color:"white"}}>Category</Link>
            </li>,
            <li className="nav-item">
                <Link className="nav-link" to="/tutorial" style={{color:"white"}}>Tutorial</Link>
            </li>,
            <li className="nav-item">
            <Link className="nav-link" to="/codesnippet" style={{color:"white"}}>Code Snippet</Link>
            </li>,
            <li className="nav-item">
            <Link className="nav-link" to="/courselist" style={{color:"white"}}>Courses</Link>
            </li>,
            <li className="nav-item">
             <Link className="nav-link" to="/student" style={{color:"white"}}>Student</Link>
            </li>,
            <li className="nav-item">
              <Link className="nav-link" to="/studentworklist" style={{color:"white"}}>Student Work</Link>
             </li>,
             <li className="nav-item">
             <Link className="nav-link" to="/fullstacklist" style={{color:"white"}}>Fullstack</Link>
            </li>,
             <li className="nav-item">
             <Link className="nav-link" to="/clientpartnerlist" style={{color:"white"}}>Client & Partner</Link>
            </li>,
            <li className="nav-item">
                <a className="nav-link" style={{color:"white",cursor:"pointer"}} onClick={()=>{localStorage.removeItem('userData')
                !render ? setRender(true) :setRender(false)
                history.push('/signin')
                }}>Sign out</a>
            </li>,
             <li className="dropdpwn">
                <img src={userDataContext.profileImage} style={{width:"2.5rem",height:"2.5rem",borderRadius:"50%",marginLeft:"1rem"}} />
                <a className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:"white", textDecoration:"none"}}><span className="profile"> {userDataContext.displayname}</span> </a> 
                <div className="dropdown-menu dropdown-menu-right" style={{marginRight:"0.5rem"}}>
                    <Link className="dropdown-item" to="#">Link 1</Link>
                    <Link className="dropdown-item" to="#">Link 2</Link>
                    <Link className="dropdown-item" to="#">Link 3</Link>
                </div>
            </li>
                ]
        }
        else{
            return[
                <li className="nav-item">
                  <Link className="nav-link" to="/signin" style={{color:"white"}}>Sign-In</Link>
                </li>,
                <li className="nav-item">
                  <Link className="nav-link" to="/signup" style={{color:"white"}}>Sign-up</Link>
                </li>
               ]
        }
    }

    return (
             <div>
                   <nav className="navbar navbar-expand-lg navbar-light bg-primary"  style={{backgroundColor:"#0e588e", color:"white"}}>
                        <a className="navbar-brand" href="#" style={{color:"white"}}>LOC-Tutorial Admin</a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                          <ul className="navbar-nav ml-auto">
                            {renderList()}                        
                         </ul>                       
                        </div>
                        </nav>                    
                    <br />
                </div>
    )
}

export default Navbar
