import React,{useEffect,useState} from 'react'
import { Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';
import Axios from 'axios'

const Dashboard = () => {
    const [categoryData, setCategoryData] = useState([])
    const [tutorialData, setTutorialData] = useState([])
    const [studentData, setStudentData] = useState([])
    const [blogData, setBlogData] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const lsData = !!localStorage.getItem("userData")

 
   useEffect(async() => {       
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + '/api/dashboard/dashboard', {withCredentials:true})
        setCategoryData(response.data.category)
        setTutorialData(response.data.tutorial)
        setStudentData(response.data.student) 
        setBlogData(response.data.blog) 
        }, [])

    if(!lsData){
        return (<Redirect to='/signin' />) 
    }

    return (
        <div className="container-fluid">
            <h3>DASHBOARD</h3>
            <hr />
            <div className="card-deck">
                    <div className="mb-md-4 card">
                    <h5 className="card-header bg-primary text-white">Total Categories</h5>
                    <div className="card-body text-center">
                        <p className="card-text">{categoryData.length}</p>
                    </div>
                    </div>
                    <div className="mb-md-4 card">
                    <h5 className="card-header bg-success text-white">Total Tutorials</h5>
                    <div className="card-body text-center">
                        <p className="card-text">{tutorialData.length}</p>
                    </div>
                    </div>

                    <div className="mb-md-4 card">
                    <h5 className="card-header bg-info text-white">Total Blogs</h5>
                    <div className="card-body text-center">
                        <p className="card-text">{blogData.length}</p>
                    </div>
                    </div>

                    <div className="mb-md-4 card">
                    <h5 className="card-header bg-info text-white">Total Students</h5>
                    <div className="card-body text-center">
                        <p className="card-text">{studentData.length}</p>
                    </div>
                    </div>
            </div>

            <div className="card">
                <h5 className="card-header">Featured</h5>
                <div className="card-body">
                    <h5 className="card-title">Special title treatment</h5>
                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                    <a href="#" className="btn btn-primary">Go somewhere</a>
            </div>
</div>


     </div>
    )
}

export default Dashboard
