import React,{useState,useEffect,Fragment} from 'react'
import {Link, Redirect } from "react-router-dom"
import moment from 'moment'
import Axios from 'axios'

const StudentList = () => {
    const [studentData, setStudentData] = useState([])
    const [categoryList, SetCategoryList] = useState({
        "categoryaccess":{}
    })
    const [studentEditData, setStudentEditData] = useState([])
    const [studentNewData, setStudentNewData] = useState("")
    const [loading, setLoading] = useState(false)
    const lsData = !!localStorage.getItem("userData")

    useEffect(async() => {       
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + '/api/student/allstudent', {withCredentials:true})
        SetCategoryList(response.data.categoryaccess)
        setStudentData(response.data)    
    }, [])

    if(!lsData){
      return (<Redirect to='/signin' />) 
    }

    const selectEditData = async(id) =>{
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/student/getstudentid/${id}`, {withCredentials:true})
        setStudentEditData(response.data.student)         
    }

    const EditData = async() =>{
        setLoading(true)
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + '/api/student/editstudent',studentEditData, {withCredentials:true})
        setStudentEditData(response.data)  
        if(response.data.statusCode === 200) {
            setLoading(false)
        }       
    }

    const handleChange = (e) =>{
        setStudentEditData({...studentEditData,[e.target.name]:e.target.value})
    }
    const handleSearchChange = (e) =>{
        console.log(e.target.value)
        setStudentNewData({...studentNewData,[e.target.name]:e.target.value})
    }

    const handleNewValue = (e) =>{
        setStudentNewData({...studentNewData,[e.target.name]:e.target.value})
    }

    const SearchStudent = async() => {
        console.log(studentNewData)
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + '/api/student/findstudent', studentNewData, {withCredentials:true})
        console.log(response.data)
        setStudentData(response.data)   
    }
     
    const AddStudent = async() => {
        setLoading(true)
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + '/api/student/register', studentNewData, {withCredentials:true})
        setStudentData(response.data)
    }

    return (
        <Fragment>
        <div className="container-fluid"  style={{marginRight:"20px"}}>
                <div className="row" style={{marginRight:"20px"}}>
                <div  className="col-sm-6">
                    <div className="input-group">
                        <input type="text" className="form-control" onChange={handleSearchChange} name="emailacct" />
                        <div className="input-group-append">
                        <button className="btn btn-secondary" onClick={SearchStudent} type="button"><i className="fa fa-search"></i></button>
                        </div>
                    </div>
                    </div>
                     <div className="col-sm-6">
                     <a href="#addStudentModal" className="btn btn-primary" data-toggle="modal"  style={{float:"right"}}><i className="material-icons">&#xE147;</i><span> Add New Student</span></a>
                    </div>
                    
                </div>
                <br/>
             <table className="table table-striped table-hover">
                <thead>
                <tr className="bg-info text-white">
                    <th>Student Name</th>
                    <th>Student Email</th>
                    <th>Category List</th>
                    <th>Date Added</th>
                    <th>Add Category</th>
                    <th>View Certificate</th>
                    <th>Upload Certificate</th>
                    <th>Edit</th>
                </tr>
                </thead>
                <tbody>
                 {studentData.map(record => {
                    return (<tr key={record._id}>
                     <td style={{width:"10%"}}>{record.displayname}</td>
                     <td style={{width:"15%"}}>{record.emailacct}</td>
                     <td style={{width:"42%"}}><ul style={{listStyle:"none",paddingLeft:"0"}}>{record.categoryaccess.map(item => { return (<li  style={{display:"inline"}}>{item.title} &nbsp; &nbsp;</li>)})}</ul></td>
                     <td style={{width:"8%"}}>{moment(record.date).format('MMM DD, YYYY')}</td>
                     <td style={{width:"8%"}}><Link to={`/getstudentid/${record._id}`} className="edit"  data-toggle="modal">Add Category</Link></td>
                     <td style={{width:"8%"}}><Link to={`/viewcertificate/${record._id}`} className="edit"  data-toggle="modal">View Certificate</Link></td>
                     <td style={{width:"14%"}}><Link to={`/uploadcertificate/${record._id}`} className="edit"  data-toggle="modal">Upload Certificate</Link></td>
                     <td style={{width:"5%"}}><a href="#editStudentModal" className="edit" onClick={()=>selectEditData(record._id)} data-toggle="modal"><i className="material-icons" title="Edit">&#xE254;</i></a></td>
                   </tr>
                    )
                 })                    
                 }   
                </tbody>
            </table>
        </div>



        {/* <!-- Edit Modal HTML --> */}
                <div id="addStudentModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <form autoComplete="off">
                        <div className="modal-header">						
                        <h4 className="modal-title">Add Student</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Student Name</label>
                                <input type="text" className="form-control" name="displayname" onChange={handleNewValue} value={studentNewData.displayname || ""}  required />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" className="form-control" name="emailacct" onChange={handleNewValue} value={studentNewData.emailacct || ""}  required />
                            </div>
                            <div className="form-group">
                                <label>PWD</label>
                                <input type="text" className="form-control" name="pwd" onChange={handleNewValue} value={studentNewData.pwd || ""}  required />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <input type="button" className="btn btn-default"  data-dismiss="modal" defaultValue="Cancel" />
                            <input type="submit" className="btn btn-primary" defaultValue="Add"  onClick={()=>AddStudent()} />
                        </div>
                    </form>
                    </div>
                </div>
                </div>

                {/* <!-- Edit Modal HTML --> */}

                <div id="editStudentModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <form autoComplete="off">
                        <div className="modal-header">						
                        <h4 className="modal-title">Edit Student</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div className="modal-body">					
                            <div className="form-group">
                                <label>Student Name</label>
                                <input type="text" className="form-control" name="displayname" disabled onChange={handleChange} value={studentEditData.displayname || ""} required />
                            </div>
                            <div className="form-group">
                                <label>Student Email</label>
                                <input type="text" className="form-control" name="emailacct"  onChange={handleChange} value={studentEditData.emailacct || ""} required />
                            </div>
                        </div>
                        <div className="modal-footer">
                        <input type="button" className="btn btn-default" data-dismiss="modal" defaultValue="Cancel" />
                        <input type="submit" className="btn btn-primary"  onClick={()=>EditData()} defaultValue="Save" />
                        </div>
                    </form>
                    </div>
                </div>
                </div>
                    

        </Fragment>
    )
}

export default StudentList
