import React,{useEffect,useState} from 'react'
import {useParams,useHistory} from "react-router-dom"
import Axios from 'axios'

const EditCertificate = () => {
    const [certificateData, setCertificateData] = useState("")
    const [uploadInfo, setUploadInfo] = useState({
        certificateid:'',
        projecturl:''
    })
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const {id} = useParams()

    useEffect(async() => {  
        const responseData = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/certificate/editcertificate/${id}`, {withCredentials:true})
        setCertificateData(responseData.data)  
     }, [])

     const handleChangeInput = (e) =>{
        setUploadInfo({ ...uploadInfo,[e.target.name]:e.target.value})
    }

     const UploadCertificate = async(e) => {
        e.preventDefault()
        setLoading(true)      
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/certificate/saveeditcertificate/${id}`, uploadInfo, {withCredentials:true})
        if (response.data.statusCode === "200") {
            setLoading(false)
            history.push('/student')
        }    
    }
    return (
        <div className="container-fluid">
        <div className="card-wrapper-tutorial">
            <div className="card">
                <div className="card-body">
                <h4 className="card-title">Edit Certificate</h4>
                 
                        <div className="form-group">
                            <label htmlFor="email">Certificate ID</label>
                            <input id="CertificateId" name="certificateid" type="text" maxLength="9" defaultValue={certificateData.certificateid || ""} style={{textTransform:"uppercase"}}  onChange={handleChangeInput} required className="form-control"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Project URL</label>
                            <input id="projecturl" name="projecturl" type="text" defaultValue={certificateData.projecturl || ""} onChange={handleChangeInput} required className="form-control"/>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-block"  onClick={UploadCertificate}>
                            {loading && <i className="fa fa-refresh fa-spin"></i>}
                            &nbsp;Submit</button>
                        </div>
               
                </div>
            </div>
        </div>
    </div>
    )
}

export default EditCertificate
