import React, {useState,useContext,useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import Axios from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye,faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import './customFormStyle.css'
import {UserContext} from './userContext'
import Cookies from 'universal-cookie';
import {motion} from 'framer-motion'

const Signin = () => {
    const history = useHistory()
    const [message, setMessage] = useState("")
    const [loginData, setLoginData] = useState({})
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const userDataContext = useContext(UserContext)
    const cookies = new Cookies();


const handleChange = (e) =>{
        setLoginData({ ...loginData,[e.target.name]:e.target.value})
   }

const togglePassword = () =>{
    setVisible(!visible)
}
    const login = async(e) => {
        setLoading(true)
        e.preventDefault()
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + '/api/admin/login', loginData)
        if(response.data.statusCode == 200) {
           const lsData = localStorage.setItem('userData',JSON.stringify(response.data))
            if(lsData !== null) {
                cookies.set('tokenID',Math.floor(Math.random()*(100000000-1000000+1)+1000000))
            } 
            userDataContext.setDisplayName(response.data.admin.displayname)
            userDataContext.setUserEmail(response.data.admin.emailacct)
            userDataContext.setUserId(response.data.admin.id)           
            history.push('/dashboard')
          }
            else{
                setMessage(response.data.msg)
                setLoading(false)
            }  
    }

  
const pageTransition = {
    in:{
      opacity:1,
      y:0
    },
    out:{
      opacity:0,
      y:100
    }
  }

    return (
     <motion.div  initial="out" animate="in" exit="out" variants={pageTransition}>
        <div className="container" style={{marginTop:"3%"}}>
            <div className="card-wrapper">
                <div className="card">
                <div className="card-body">
                <form autoComplete="off">
                    <h4 className="card-title">Sign In</h4>
                       <div className="form-group">
                        <label htmlFor="email">E-Mail Address</label>
                        <input id="email" type="email" className="form-control" placeholder="email" name="emailacct"  onChange={handleChange}  />
                        <div className="invalid-feedback">
                        Email is invalid
                        </div>
                    </div>
                    <div className="form-group" style={{position:"relative"}}>
                        <label htmlFor="password">Password</label>
                        <input type={visible ? "text" : "password"} className="form-control" placeholder="password" name="password"  onChange={handleChange}  />
                        <span onClick={togglePassword}>
                           <span>
                              {visible ? <FontAwesomeIcon icon={faEye} className="customIcon"/> :<FontAwesomeIcon icon={faEyeSlash} className="customIcon"/>}
                           </span>
                        </span>
                        <div className="invalid-feedback">
                        Password is required
                        </div>
                    </div>
                    <div className="form-group m-0">
                        <button type="submit" onClick={login} className="btn btn-primary btn-block" disable={loading}>
                        {loading && <i className="fa fa-refresh fa-spin"></i>}
                         &nbsp;Login
                        </button>
                    </div>
                    <br/>
                    <div className="form-group"  style={{color:"red", textAlign:"center"}}>
                            <span id="msg">{message}</span>
                    </div>
                </form>
                  </div>
                </div>
            </div>
        </div>
    </motion.div>

    )
}

export default Signin
