import React,{useEffect,useState} from 'react'
import { useHistory, Redirect } from "react-router-dom";
import Axios from 'axios'


const AddClientPartner = () => {
  const [uploadInfo, setUploadInfo] = useState("")
  const [imageData, setImageData] = useState([])
  const [uploadButtonData, setUploadButtonData] = useState("Upload Client Logo Image")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const { clientpartnercompanyname} = uploadInfo

  const handleChange = (e) => {
      setImageData(e.target.files[0])
      setUploadButtonData(e.target.files[0].name)
  }

  const handleChangeInput = (e) => {
      setUploadInfo({ ...uploadInfo, [e.target.name]: e.target.value })
  }


  const UploadClientPartner = async (e) => {
      setLoading(true)
      const formData = new FormData();
      formData.append("image", imageData);
      formData.append("clientpartnercompanyname", clientpartnercompanyname);
      e.preventDefault()
      const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/clientpartner/addclientpartner/`, formData, { withCredentials: true })
      if (response.data.statusCode === "200") {
          setLoading(false)
          history.push('/clientpartnerlist')
      }
  }
  return (
    <div className="container-fluid">
    <div className="card-wrapper-tutorial">
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Add New Client Partner</h4>
                <form autoComplete="off">
                <div className="form-group">
                        <label htmlFor="email">Client Partner Company Name</label>
                        <input id="clientpartnercompanyname" name="clientpartnercompanyname" type="text" onChange={handleChangeInput} className="form-control" />
                    </div>               
                    <div className="form-group">
                        <label for="files" className="btn btn-outline-secondary">{uploadButtonData}
                            <input id="files" name="image" className="form-control" hidden accept="image/*" onChange={handleChange} type="file" /></label>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-block" onClick={UploadClientPartner}>
                            {loading && <i className="fa fa-refresh fa-spin"></i>}
                            &nbsp;Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
  )
}

export default AddClientPartner