import React, { useState, useEffect } from 'react'
import { Link, Redirect, useHistory } from "react-router-dom";
import Axios from 'axios'


const AddStudentWork = () => {

    const [uploadInfo, setUploadInfo] = useState("")
    const [categoryData, setCategoryData] = useState([])
    const [imageData, setImageData] = useState([])
    const [uploadButtonData, setUploadButtonData] = useState("Upload Avatar Image")
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        async function getCourseCategoryData() {
          const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/course/getcoursetitle/`, { withCredentials: true });  
          setCategoryData(response.data);
        }  
        getCourseCategoryData()
      }, []);

    const {studentname,studentorganisation, studentworkprojectname, studentworkmetadescription, studentworkmetakeyword, studentworkurl } = uploadInfo

    const handleChange = (e) => {
        setImageData(e.target.files[0])
        setUploadButtonData(e.target.files[0].name)
    }

    const handleChangeInput = (e) => {
        setUploadInfo({ ...uploadInfo, [e.target.name]: e.target.value })
    }

    const UploadProjectAvatar= async (e) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("image", imageData);
        formData.append("studentname", studentname);
        formData.append("studentorganisation", studentorganisation);
        formData.append("studentworkprojectname", studentworkprojectname);
        formData.append("studentworkmetadescription", studentworkmetadescription);
        formData.append("studentworkmetakeyword", studentworkmetakeyword);
        formData.append("studentworkurl", studentworkurl);
        formData.append("courseid", uploadInfo.category);
        e.preventDefault()
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/studentwork/addstudentwork/`, formData, { withCredentials: true })
        if (response.data.statusCode === "200") {
            setLoading(false)
            history.push('/studentworklist')
        }
    }

  return (
    <div className="container-fluid">
    <div className="card-wrapper-tutorial">
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Add New Student Work</h4>
                <form autoComplete="off">
                <div className="form-group">
                        <label htmlFor="email">Student Name</label>
                        <input id="studentname" name="studentname" type="text" onChange={handleChangeInput} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Student Organisation</label>
                        <input id="studentorganisation" name="studentorganisation" type="text" onChange={handleChangeInput} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Student Project Work Title</label>
                        <input id="studentworkprojectname" name="studentworkprojectname" type="text" onChange={handleChangeInput} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Student Work Meta Description</label>
                        <input id="studentworkmetadescription" name="studentworkmetadescription" type="text" onChange={handleChangeInput} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Student Work Tech Stack Meta Keyword</label>
                        <input id="studentworkmetakeyword" name="studentworkmetakeyword" type="text" onChange={handleChangeInput} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Project URL</label>
                        <input id="studentworkurl" name="studentworkurl" type="text" maxLength="100" onChange={handleChangeInput} required className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="Course Amount">Course Category</label>
                        <select id="categoryid"  className="form-control" name="category" onChange={handleChangeInput}>
                                   {categoryData.map(option => (
                                        <option value={option._id} >{option.coursetitle}</option>
                                    )) }
                         </select>
                     </div>
                    <div className="form-group">
                        <label for="files" className="btn btn-outline-secondary">{uploadButtonData}
                            <input id="files" name="image" className="form-control" hidden accept="image/*" onChange={handleChange} type="file" /></label>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-block" onClick={UploadProjectAvatar}>
                            {loading && <i className="fa fa-refresh fa-spin"></i>}
                            &nbsp;Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
  )
}

export default AddStudentWork