import React,{useState,useEffect,Fragment} from 'react'
import { Redirect } from "react-router-dom"
import Axios from 'axios'

const CategoryList = () => {
    const [categoryData, setCategoryData] = useState([])
    const [categoryEditData, setCategoryEditData] = useState([])
    const [categoryNewData, setCategoryNewData] = useState({})
    const [loading, setLoading] = useState(false)
    const lsData = !!localStorage.getItem("userData")
    

    useEffect(async() => {       
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + '/api/category/getadmincategory', {withCredentials:true})
        setCategoryData(response.data)    
    }, [])

    if(!lsData){
      return (<Redirect to='/signin' />) 
    }
    const selectEditData = async(id) =>{
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/category/getcategoryid/${id}`, {withCredentials:true})
        setCategoryEditData(response.data)         
    }

    const EditData = async() =>{
        setLoading(true)
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + '/api/category/editcategory',categoryEditData, {withCredentials:true})
        setCategoryEditData(response.data)  
        if(response.data.statusCode === 200) {
            setLoading(false)
        }       
    }

    const handleChange = (e) =>{
        setCategoryEditData({...categoryEditData,[e.target.name]:e.target.value})
    }
    const handleNewValue = (e) =>{
        setCategoryNewData({...categoryNewData,[e.target.name]:e.target.value})
    }
     
    const AddCategory = async() => {
        setLoading(true)
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + '/api/category/addcategory', categoryNewData, {withCredentials:true})
        if(response.data.statusCode === 200) {
               setLoading(false)
            }  
    }

    return (
        <Fragment>
        <div className="container-fluid"  style={{marginRight:"20px"}}>
                <div className="row" style={{marginRight:"20px"}}>
                     <div className="col-sm-12">
                     <a href="#addEmployeeModal" className="btn btn-primary" data-toggle="modal"  style={{float:"right"}}><i className="material-icons">&#xE147;</i><span> Add New Category</span></a>
                    </div>
                </div>
                <br/>
             <table className="table table-striped table-hover">
                <thead>
                <tr className="bg-info text-white">
                    <th>Category Name</th>
                    <th>Edit</th>
                </tr>
                </thead>
                <tbody>
                 {categoryData.map(record => {
                    return (<tr key={record._id}>
                     <td style={{width:"90%"}}>{record.title}</td>
                     <td style={{width:"10%"}}><a href="#editEmployeeModal" className="edit" onClick={()=>selectEditData(record._id)} data-toggle="modal"><i className="material-icons" title="Edit">&#xE254;</i></a></td>
                   </tr>
                    )
                 })                    
                 }   
                </tbody>
            </table>
        </div>



        {/* <!-- Edit Modal HTML --> */}
<div id="addEmployeeModal" className="modal fade">
  <div className="modal-dialog">
    <div className="modal-content">
      <form autoComplete="off">
        <div className="modal-header">						
          <h4 className="modal-title">Add Category</h4>
          <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div className="modal-body">					
          <div className="form-group">
            <label>Title</label>
            <input type="text" className="form-control" name="title" onChange={handleNewValue} value={categoryNewData.title || ""}  required />
          </div>
        </div>
        <div className="modal-footer">
          <input type="button" className="btn btn-default"  data-dismiss="modal" defaultValue="Cancel" />
          <input type="submit" className="btn btn-primary" defaultValue="Add"  onClick={()=>AddCategory()} />
        </div>
      </form>
    </div>
  </div>
</div>

{/* <!-- Edit Modal HTML --> */}

<div id="editEmployeeModal" className="modal fade">
  <div className="modal-dialog">
    <div className="modal-content">
      <form autoComplete="off">
        <div className="modal-header">						
          <h4 className="modal-title">Edit Category</h4>
          <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div className="modal-body">					
          <div className="form-group">
            <label>Title</label>
            <input type="text" className="form-control" name="title"  onChange={handleChange} value={categoryEditData.title || ""} required />
          </div>
        </div>
        <div className="modal-footer">
          <input type="button" className="btn btn-default" data-dismiss="modal" defaultValue="Cancel" />
          <input type="submit" className="btn btn-primary"  onClick={()=>EditData()} defaultValue="Save" />
        </div>
      </form>
    </div>
  </div>
</div>
     

        </Fragment>
        
    )
}

export default CategoryList
