import React,{useState,useEffect} from 'react'
import {useHistory,useParams } from "react-router-dom"
import Axios from 'axios'

const UploadCertificate = () => {
    const [studentData, setStudentData] = useState([])
    const [uploadInfo, setUploadInfo] = useState({
        certificateid:'',
        projecturl:''
    })
    const [imageData, setImageData] = useState([])
    const [uploadButtonData, setUploadButtonData] = useState("Upload Certificate")
    const [loading, setLoading] = useState(false)
    const {id} = useParams()
    const history = useHistory()

    const{certificateid,projecturl} = uploadInfo

    useEffect(async() => {  
        const responseStudent = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/student/getstudentid/${id}`, {withCredentials:true})
        setStudentData(responseStudent.data.student)  
     }, [])

    const handleChange = (e) =>{
        setImageData(e.target.files[0])
        setUploadButtonData(e.target.files[0].name)
   }

    const handleChangeInput = (e) =>{
        setUploadInfo({ ...uploadInfo,[e.target.name]:e.target.value})
    }
  
     const UploadCertificate = async(e) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("image", imageData);
        formData.append("studentid", id);
        formData.append("certificateid", certificateid);
        formData.append("projecturl", projecturl);
        e.preventDefault()
     
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/certificate/uploadcertificate/`, formData, {withCredentials:true})
        if (response.data.statusCode === "200") {
            setLoading(false)
            history.push('/student')
        }    
    }

    return (
        <div className="container-fluid">
        <div className="card-wrapper-tutorial">
            <div className="card">
                <div className="card-body">
                <h4 className="card-title">Upload Certificate</h4>
                    <form autoComplete="off">
                        <div className="form-group">
                            <label htmlFor="email">Student Name</label>
                            <label id="title" className="form-control">{studentData.displayname}</label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Certificate ID</label>
                            <input id="certificateid" name="certificateid" type="text" maxLength="9"  style={{textTransform:"uppercase"}}  onChange={handleChangeInput} required className="form-control"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Project URL</label>
                            <input id="projecturl" name="projecturl" type="text" placeholder="https://" onChange={handleChangeInput} required className="form-control"/>
                        </div>
                        <div className="form-group">
                           <label for="files" className="btn btn-outline-secondary">{uploadButtonData}
                           <input id="files" name="image" className="form-control" hidden accept="image/*" onChange={handleChange} type="file"/></label>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-block"  onClick={UploadCertificate}>
                            {loading && <i className="fa fa-refresh fa-spin"></i>}
                            &nbsp;Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}

export default UploadCertificate
