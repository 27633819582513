import React,{useState,useEffect,Fragment} from 'react'
import {Link, Redirect } from "react-router-dom"
import moment from 'moment'
import Axios from 'axios'

const ViewFullstack = () => {
    const [fullstackData, setFullstackkData] = useState([])
    const [studentEditData, setStudentEditData] = useState([])
    const [studentNewData, setStudentNewData] = useState("")
    const [loading, setLoading] = useState(false)
    const lsData = !!localStorage.getItem("userData")

    useEffect(() => {
        async function getFullstackData() {
          const response = await Axios.get(process.env.REACT_APP_FETCH_URL + '/api/fullstack/viewtitle', { withCredentials: true });
          setFullstackkData(response.data);
        }
        getFullstackData()
      }, []);

    if(!lsData){
        return (<Redirect to='/signin' />) 
      }


  return (
     <Fragment>
    <div className="container-fluid"  style={{marginRight:"20px"}}>
            <div className="row" style={{marginRight:"20px"}}>
            <div  className="col-sm-6">
                </div>
                 <div className="col-sm-6">
                 <Link to="/addfullstack" className="btn btn-primary" data-toggle="modal"  style={{float:"right"}}><i className="material-icons">&#xE147;</i><span> Add New Fullstack</span></Link>
                </div>
                
            </div>
            <br/>
         <table className="table table-striped table-hover">
            <thead>
            <tr className="bg-info text-white">
                <th>Fullstack Title</th>
                <th>Date Added</th>
                <th>Edit</th>
            </tr>
            </thead>
            <tbody>
             {fullstackData.map(record => {
                return (<tr key={record._id}>
                 <td style={{width:"10%"}}>{record.fullstacktitle}</td>
                 <td style={{width:"10%"}}>{moment(record.date).format('MMM DD, YYYY')}</td>
                 <td style={{width:"5%"}}><Link to={`/editfullstack/${record._id}`} className="edit"  data-toggle="modal">Edit</Link></td>
               </tr>
                )
             })                    
             }   
            </tbody>
        </table>
    </div>
    </Fragment>
  )
}

export default ViewFullstack