import React,{useEffect,useState} from 'react'
import { useHistory, Redirect } from "react-router-dom";
import Axios from 'axios'

const AddTutorial = () => {
    const [courseData, setCourseData] = useState([])
    const [selectData, setSelectData] = useState('')
    const [message , setMessage] = useState('')
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [body, setBody] = useState("")
    const lsData = !!localStorage.getItem("userData")
    const history = useHistory()

    useEffect(async() => {       
      const response = await Axios.get(process.env.REACT_APP_FETCH_URL + '/api/course/viewcourses', {withCredentials:true})
      setCourseData(response.data)
    }, [])

    if(!lsData){
        return (<Redirect to='/signin' />) 
    }

    const handleChange = (e) =>{
        setSelectData({ ...selectData,[e.target.name]:e.target.value})
   }

   const postFeedback = async(e) =>{
    e.preventDefault()
    setLoading(true)
    const response = await Axios.post(process.env.REACT_APP_FETCH_URL + '/api/feedback/addfeedback', selectData, {withCredentials:true})
        if(response.data.statusCode === "409") {
            setMessage(response.data.msg)
            setLoading(false)
        }
        else if (response.data.statusCode === "200") {
            history.push('/viewfeedback')
        }    
    }


    return (
        <div className="container-fluid">
            <div className="card-wrapper-tutorial">
                <div className="card">
                    <div className="card-body">
                    <h4 className="card-title">New Feedback</h4>
                        <form autoComplete="off">
                        <div className="form-group">
                                <label htmlFor="email">Course Title</label>
                                <select id="courseTitle" className="form-control" name="coursetitle" onChange={handleChange}>
                                 <option value={0}>Please Select One</option>
                                    {courseData.map(option => (
                                        <option value={option._id}>{option.coursetitle}</option>
                                    )) }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Student Name</label>
                                <input id="studentname" type="text" className="form-control"  placeholder="title" name="studentname"  disabled = {disabled}  onChange={handleChange}  />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Feedback Text</label>
                                <textarea id="feedbacktext" className="form-control"  rows={7} defaultValue={""}  placeholder="Body text" name="feedbacktext"  disabled = {disabled}  onChange={handleChange}  />
                            </div>
                           
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-block"  onClick={postFeedback}>
                            {loading && <i className="fa fa-refresh fa-spin"></i>}
                            &nbsp;Submit</button>
                            </div>
                            <div className="form-group"  style={{color:"red",textAlign:"center",fontWeight:"bold"}}>
                                <span id="msg">{message}</span>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddTutorial
