import React,{useState,useEffect,Fragment} from 'react'
import {Link, Redirect } from "react-router-dom"
import moment from 'moment'
import Axios from 'axios'

const ClientPartner = () => {
  const [clientpartnerData, setClientPartnerData] = useState([])
  const [studentEditData, setStudentEditData] = useState([])
  const [studentNewData, setStudentNewData] = useState("")
  const [loading, setLoading] = useState(false)
  const lsData = !!localStorage.getItem("userData")

  useEffect(() => {
      async function getClientPartnerData() {
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + '/api/clientpartner/viewclientpartner', { withCredentials: true });
        setClientPartnerData(response.data);
      }
      getClientPartnerData()
    }, []);

  if(!lsData){
      return (<Redirect to='/signin' />) 
    }



  return (
    <Fragment>
    <div className="container-fluid"  style={{marginRight:"20px"}}>
            <div className="row" style={{marginRight:"20px"}}>
            <div  className="col-sm-6">
                </div>
                 <div className="col-sm-6">
                 <Link to="/addclientpartner" className="btn btn-primary" data-toggle="modal"  style={{float:"right"}}><i className="material-icons">&#xE147;</i><span> Add New Client Partner</span></Link>
                </div>
                
            </div>
            <br/>
         <table className="table table-striped table-hover">
            <thead>
            <tr className="bg-info text-white">
                <th>Client Partner Company Name</th>
                <th>Client Partner Company Logo</th>
                <th>Date Added</th>
                <th>Edit</th>
            </tr>
            </thead>
            <tbody>
             {clientpartnerData.map(record => {
                return (<tr key={record._id}>
                 <td style={{width:"10%"}}>{record.clientpartnercompanyname}</td>
                 <td style={{width:"10%"}}><img src={record.clientpartnerlogourl}/></td>
                 <td style={{width:"10%"}}>{moment(record.date).format('MMM DD, YYYY')}</td>
                 <td style={{width:"5%"}}><Link to={`/editclientpartner/${record._id}`} className="edit"  data-toggle="modal">Edit</Link></td>
               </tr>
                )
             })                    
             }   
            </tbody>
        </table>
    </div>
    </Fragment>
  )
}

export default ClientPartner