import React, { useState, useEffect } from 'react'
import { Link, Redirect, useHistory } from "react-router-dom";
import Axios from 'axios'
import Linkify from 'react-linkify';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";

const AddFullstack = () => {
    const [uploadInfo, setUploadInfo] = useState("")
    const [imageData, setImageData] = useState([])
    const [uploadButtonData, setUploadButtonData] = useState("Upload Fullstack Image")
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const [fullstackDescription, setFullstackDescription] = useState("")

    
    const { fullstacktitle, fullstackmetadescription, fullstacketakeyword,fullstackamount, fullstackdescription } = uploadInfo

    
    const handleChange = (e) => {
        setImageData(e.target.files[0])
        setUploadButtonData(e.target.files[0].name)
    }

    const handleChangeInput = (e) => {
        setUploadInfo({ ...uploadInfo, [e.target.name]: e.target.value })
    }

    const handleContentChange = (e) => {
        setFullstackDescription(e)
    }

    const UploadCertificate = async (e) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("image", imageData);
        formData.append("fullstacktitle", fullstacktitle);
        formData.append("fullstackmetadescription", fullstackmetadescription);
        formData.append("fullstacketakeyword", fullstacketakeyword);
        formData.append("fullstackamount", fullstackamount);
        formData.append("fullstackdescription", fullstackDescription);
        e.preventDefault()
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/fullstack/addfullstack/`, formData, { withCredentials: true })
        if (response.data.statusCode === "200") {
            setLoading(false)
            history.push('/fullstacklist')
        }
    }

  return (
    <div className="container-fluid">
    <div className="card-wrapper-tutorial">
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Add Fullstack Course</h4>
                <form autoComplete="off">
                    <div className="form-group">
                        <label htmlFor="email">Fullstack Title</label>
                        <input id="fullstacktitle" name="fullstacktitle" type="text" onChange={handleChangeInput} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Fullstack Meta Description</label>
                        <input id="fullstackmetadescription" name="fullstackmetadescription" type="text" onChange={handleChangeInput} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Fullstack Meta Keyword</label>
                        <input id="fullstackmetakeyword" name="fullstackmetakeyword" type="text" onChange={handleChangeInput} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="Course Amount">Fullstack Amount (SGD)</label>
                        <input id="fullstackamount" type="number" name="fullstackamount" onChange={handleChangeInput} className="form-control" maxLength="100" />
                    </div>
                     <div className="form-group">
                        <label htmlFor="email">Fullstack Description</label>
                        <ReactQuill
                            value={fullstackDescription}
                            placeholder="Start writing something"
                            modules={AddFullstack.modules}
                            formats={AddFullstack.formats}
                            onChange={handleContentChange} />
                    </div>
                    <div className="form-group">
                        <label for="files" className="btn btn-outline-secondary">{uploadButtonData}
                            <input id="files" name="image" className="form-control" hidden accept="image/*" onChange={handleChange} type="file" /></label>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-block" onClick={UploadCertificate}>
                            {loading && <i className="fa fa-refresh fa-spin"></i>}
                            &nbsp;Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
  )
}

AddFullstack.modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { header: [2, 3, 4, 5, 6] }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['link', 'image', 'video'],
        ['clean'],
        ['code-block']
    ]
};

AddFullstack.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'align',
    'indent',
    'bullet',
    'link',
    'image',
    'video',
    'code-block'
];

export default AddFullstack