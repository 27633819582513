import React,{useEffect,useState} from 'react'
import {Redirect,useHistory } from "react-router-dom";
import Axios from 'axios'
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";


const AddCodeSnippet = () => {
    const [categoryData, setCategoryData] = useState([])
    const [selectData, setSelectData] = useState('')
    const [message , setMessage] = useState('')
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const lsData = !!localStorage.getItem("userData")
    const [ blogContent, setBlogContent] = useState("")
    const  [convertedContent, setConvertedContent] = useState(null);

    const history = useHistory()

    useEffect(async() => {       
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + '/api/category/getadmincategory', {withCredentials:true})
        setCategoryData(response.data) 
    }, [])

    if(!lsData){
        return (<Redirect to='/signin' />) 
    }

  const handleChange = (e) =>{
      setSelectData({ ...selectData,[e.target.name]:e.target.value})
   }

   const handleContentChange = (e) => {
    setBlogContent(e)
  }

    const postBlog = async(e) =>{
        e.preventDefault()
        setLoading(true)

        const user = {
            title: selectData.title,
            blogdesc:selectData.blogdesc,
            category:selectData.category,
            bodytext:blogContent
          };

        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + '/api/blog/addblog', user, {withCredentials:true})
        // console.log(response.data)
        if(response.data.statusCode === "409") {
                setMessage(response.data.msg)
                setLoading(false)
            }
            else if (response.data.statusCode === "200") {
                history.push('/codesnippet')
            } 
        setLoading(false)   
        }

    return (
        <div className="container-fluid">
        <div className="card-wrapper-tutorial">
            <div className="card">
                <div className="card-body">
                <h4 className="card-title">New Code Snippet</h4>
                    <form autoComplete="off">
                        <div className="form-group">
                            <label htmlFor="email">Title</label>
                            <input id="title" type="text" className="form-control"  placeholder="title" name="title"  disabled = {disabled}  onChange={handleChange}  />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Blog Description</label>
                            <textarea id="blogdesc" rows={7} defaultValue={""} className="form-control"  placeholder="short description" name="blogdesc"  disabled = {disabled}  onChange={handleChange}  />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Body</label>
                            <ReactQuill 
                                value={blogContent}
                                placeholder="Start writing something"
                                modules={AddCodeSnippet.modules}
                                formats={AddCodeSnippet.formats}
                                onChange={handleContentChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Category</label>
                            <select id="categoryid" className="form-control" name="category" onChange={handleChange}>
                             <option value={0}>Please Select One</option>
                                {categoryData.map(option => (
                                    <option value={option._id}>{option.title}</option>
                                )) }
                            </select>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-block"  onClick={postBlog}>
                        {loading && <i className="fa fa-refresh fa-spin"></i>}
                        &nbsp;Submit</button>
                        </div>
                        <div className="form-group"  style={{color:"red",textAlign:"center",fontWeight:"bold"}}>
                            <span id="msg">{message}</span>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}

AddCodeSnippet.modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'],
        ['clean'],
        ['code-block']
    ]
};
 
AddCodeSnippet.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'indent',
    'bullet',
    'link',
    'image',
    'video',
    'code-block'
];


export default AddCodeSnippet
