import React,{useState,useEffect,Fragment} from 'react'
import {Link,Redirect} from "react-router-dom";
import Axios from 'axios'
import Linkify from 'react-linkify';


const CourseList = () => {
    const [courseData, setCourseData] = useState([])
    const lsData = !!localStorage.getItem("userData")

    useEffect(async() => {       
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + '/api/course/viewcourses', {withCredentials:true})
        setCourseData(response.data)
    }, [])

    if(!lsData){
        return (<Redirect to='/signin' />) 
    }
    

    return (
        <Fragment>
            <div className="container-fluid"  style={{marginRight:"20px"}}>
                <div className="row" style={{marginRight:"20px"}}>
                     <div className="col-sm-12">
                        <Link to="/addcourse" className="btn btn-primary" data-toggle="modal"  style={{float:"right"}}><i className="material-icons">&#xE147;</i><span> Add New Course</span></Link>
                    </div>
                </div>
                <br/>
                <hr/>
                <div className="container card-group">
                <div className="row">
                    {
                        courseData.map((courseinfo) =>{
                            return(
                                <div class="col-sm-4">
                                <div className="card">
                                    <img className="card-img-top" src={courseinfo.courseimageurl}  alt="Card image cap" />
                                    <div className="card-body">
                                    <h5 className="card-title" style={{height:"80px"}}>{courseinfo.coursetitle}</h5>

                                    <div className="card-block" style={{textAlign:"left"}}>                                 
                                        {courseinfo.coursedescription== "" ? <span  className="card-link">Coming Soon</span> :
                                        <Link  className="btn btn-primary m-1" key={courseinfo._id}  to={`/uploadcoursebrochure/${courseinfo.courseslug}`}>Upload Course Brochure</Link>}
                                        <Link  to={`/editcourse/${courseinfo.courseslug}`} className="btn btn-primary m-1">Edit</Link>
                                        <Link  to={`/addschedule/${courseinfo._id}`} className="btn btn-primary m-1">Add Schedule</Link>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            )
                        }

                        )
                    }

                </div>
                </div>
            </div>            
        </Fragment>
    )
}

export default CourseList
