import React,{useEffect,useState} from 'react'
import {useParams,Link} from "react-router-dom"
import Axios from 'axios'

const ViewCertificate = () => {
    const [studentData, setStudentData] = useState([])
    const {id} = useParams()

    useEffect(async() => {  
        const responseStudent = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/certificate/viewallcertificate/${id}`, {withCredentials:true})
        setStudentData(responseStudent.data)  
     }, [])

    return (
        <div className="container-fluid">
          
            <div className="card align-items-center" style={{border:"none"}}>
            <h1 >Certificates</h1>           
                         {
                      studentData.map(record => {
                            return(<div>
                                        <img className="card-img-top" src={record.certificateurl}/>
                                        <div style={{ textAlign:"right", padding:"0.5rem"}}>
                                            <span style={{paddingRight:"2rem"}}>Project URL: <strong>{record.projecturl}</strong></span>    <Link to={`/editcertificate/${record._id}`} className="align-items-right"   data-toggle="modal">Edit Certificate</Link>
                                        </div>
                                        <hr/>
                                    </div>
                                     )
                           
                      }) 
                   }
            </div>
        </div>
    )
}

export default ViewCertificate
