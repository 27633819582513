import React,{useState,useEffect,useRef} from 'react'
import {Link, Redirect,useParams } from "react-router-dom"
import moment from 'moment'
import Axios from 'axios'

const StudentAddCategory = () => {
    const [categoryData, setCategoryData] = useState([])
    const [categoryAccessData, setCategoryAccessData] = useState([])
    const [studentData, setStudentData] = useState([])
    const [studentAddCategory, setStudentAddCategory] = useState([])
    const [loading, setLoading] = useState(false)
    const lsData = !!localStorage.getItem("userData")
    const {id} = useParams()

    
    useEffect(async() => {  
        const responseStudent = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/student/getstudentid/${id}`, {withCredentials:true})
        setCategoryAccessData(responseStudent.data.student.categoryaccess)
        setStudentData(responseStudent.data.student)
        setCategoryData(responseStudent.data.category)
  
     }, [])
 
     const AddStudentCategory = async(itemid) => {
         const response = await Axios.put(process.env.REACT_APP_FETCH_URL + `/api/student/addstudentcategory/${id}`, {"category":itemid}, {withCredentials:true})
         //setCategoryAccessData(response.data.categoryaccess)
       // e.preventDefault()
     }

    const DeleteStudentCategory = async(itemid) => {
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/student/deletestudentcategory/${id}`,{"category":itemid}, {withCredentials:true})
         setCategoryAccessData(response.data.categoryaccess)
       }

    const handleChange = (e) =>{
        setStudentAddCategory(e.target.value)
       }
    

    if(!lsData){
        return (<Redirect to='/signin' />) 
      }

    return (
        <div className="container-fluid">
        <div className="card-wrapper-tutorial">
            <div className="card">
                <div className="card-body">
                <h4 className="card-title">Category Access Control - Students</h4>
                    <form autoComplete="off">
                        <div className="form-group">
                            <label htmlFor="email">Student Name</label>
                            <label id="title" className="form-control">{studentData.displayname}</label>
                        </div>
                        <div className="form-group">
                                <select id="categoryid" className="form-control" name="category" onChange={handleChange}>
                                 <option value={'5fef2a39f73e6effffffffff'}>Please Select One</option>
                                    {categoryData.map(option => (
                                        <option value={option._id}>{option.title}</option>
                                    )) }
                                </select>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-block"  onClick={() => {AddStudentCategory(studentAddCategory)}}>                      
                        &nbsp;Submit</button>
                        </div>
                        <div className="form-group">
                            <h5>Category Access List</h5><br/>
                            <ul className="list-group">
                            {categoryAccessData.map(items => (
                                    <li className="list-group-item" key={items._id}>{items.title}<button className="btn btn-secondary" onClick={() => {DeleteStudentCategory(items._id)}}  style={{float:"right"}} type="button"><i className="fa fa-trash"></i></button></li>
                                ))}
                               
                            </ul>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}

export default StudentAddCategory
