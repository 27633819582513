import React, { useState, useEffect } from 'react'
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import Axios from 'axios'


const EditClientPartner = () => {
  const [clientpartnerData, setClientPartnerData] = useState("")
  const [imageData, setImageData] = useState([])
  const [uploadButtonData, setUploadButtonData] = useState("Upload Client Logo Image")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { id } = useParams()

  useEffect(() => {
    async function getClientPartnerData() {
      const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/clientpartner/editclientpartner/${id}`, { withCredentials: true });
      setClientPartnerData(response.data);
    }  
    getClientPartnerData()
  }, []);

  const { clientpartnercompanyname } = clientpartnerData

  const handleChange = (e) => {
    setImageData(e.target.files[0])
    setUploadButtonData(e.target.files[0].name)
  }

  const handleChangeInput = (e) => {
    setClientPartnerData({ ...clientpartnerData, [e.target.name]: e.target.value })
  }

  const UploadClientPartner = async (e) => {
    setLoading(true)
    const formData = new FormData();
    formData.append("image", imageData);
    formData.append("clientpartnercompanyname", clientpartnercompanyname);
    e.preventDefault()
    const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/clientpartner/saveeditclientpartner/${id}`, formData, { withCredentials: true })
    if (response.data.statusCode === "200") {
      setLoading(false)
      history.push('/clientpartnerlist')
    }
  }

  return (
    <div className="container-fluid">
    <div className="card-wrapper-tutorial">
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Edit Client Partner</h4>
                <form autoComplete="off">
                    <div className="form-group">
                        <label htmlFor="email">Client Partner Company Name</label>
                        <input id="clientpartnercompanyname" name="clientpartnercompanyname" type="text" defaultValue={clientpartnerData.clientpartnercompanyname || ""} onChange={handleChangeInput} className="form-control" />
                    </div>
                     <div className="form-group">
                        <label for="files" className="btn btn-outline-secondary">{uploadButtonData}
                            <input id="files" name="image" className="form-control" hidden accept="image/*" onChange={handleChange} type="file" /></label>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-block" onClick={UploadClientPartner}>
                            {loading && <i className="fa fa-refresh fa-spin"></i>}
                            &nbsp;Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
  )
}

export default EditClientPartner