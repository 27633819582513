import React,{useEffect,useState} from 'react'
import { useHistory, Redirect,useParams } from "react-router-dom";
import Axios from 'axios'

const EditTutorial = () => {
    const [categoryData, setCategoryData] = useState([])
    const [tutorialData, setTutorialData] = useState({category:{}})
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const {id} = useParams()
    const lsData = !!localStorage.getItem("userData")
    const history = useHistory()

    useEffect(async() => {       
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/tutorial/edittutorial/${id}`, {withCredentials:true})
        setTutorialData(response.data.tutorial)
        setCategoryData(response.data.category)
    }, [])

    if(!lsData){
        return (<Redirect to='/signin' />) 
    }

    const handleChange = (e) =>{
        setTutorialData({ ...tutorialData,[e.target.name]:e.target.value})
   }

   const postTutorial = async(e) =>{
        e.preventDefault()
        setLoading(true)
       const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/tutorial/saveedittutorial/${id}`, tutorialData, {withCredentials:true})
       history.push('/tutorial')
    }

   

    return (
        <div className="container-fluid">
            <div className="card-wrapper-tutorial">
                <div className="card">
                    <div className="card-body">
                    <h4 className="card-title">Edit Tutorial</h4>
                        <form autoComplete="off">
                            <div className="form-group">
                                <label htmlFor="email">Title</label>
                                <input id="title" type="text" className="form-control"  placeholder="title" name="title"  disabled = {disabled}  onChange={handleChange} value={tutorialData.title || ""} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Body</label>
                                <textarea id="bodytext" className="form-control"  rows={7}  placeholder="title" name="bodytext"  disabled = {disabled}  onChange={handleChange}   value={tutorialData.bodytext || ""} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Category</label>
                                <select id="categoryid"  className="form-control" name="category" onChange={handleChange}>
                                   {categoryData.map(option => (
                                        <option value={option._id} >{option.title}</option>
                                    )) }
                                    <option selected value={tutorialData.category._id}>{tutorialData.category.title}</option>
                                </select>
                            </div>
                            <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-block"  onClick={postTutorial}>
                            {loading && <i className="fa fa-refresh fa-spin"></i>}
                            &nbsp;Submit</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditTutorial
