import React,{useState,useEffect,Fragment} from 'react'
import Axios from 'axios'
import {Redirect,useParams, useHistory} from "react-router-dom";
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";

const EditCodeSnippet = () => {
    const [blogData, setBlogData] = useState({category:{}})
    const [blogContent, setBlogContent] = useState("")
    const [categoryData, setCategoryData] = useState([])
    const [loading, setLoading] = useState(false)
    const [message , setMessage] = useState('')
    const [disabled, setDisabled] = useState(false)
    const {id} = useParams()
    const lsData = !!localStorage.getItem("userData")

    const history = useHistory()
    
    useEffect(async() => {
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/blog/viewblog/${id}`, {withCredentials:true})
        setCategoryData(response.data.category)
        setBlogData(response.data.blog)
        setBlogContent(response.data.blog.bodytext)
    }, [])

    if(!lsData){
        return (<Redirect to='/signin' />) 
        }
    
        const handleChange = (e) =>{
            setBlogData({ ...blogData,[e.target.name]:e.target.value})
         }
      
         const handleContentChange = (e) => {
            setBlogContent(e)
          }

    const postBlog = async(e) =>{
              e.preventDefault()
              setLoading(true)
               const user = {
                  title: blogData.title,
                  category:blogData.category,
                  bodytext:blogContent
                };

            //console.log(user)
              const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/blog/saveeditblog/${id}`, user, {withCredentials:true})
              // console.log(response.data)
                  if(response.data.statusCode === "409") {
                      setMessage(response.data.msg)
                      setLoading(false)
                  }
                  else if (response.data.statusCode === "200") {
                      history.push('/codesnippet')
                  } 
              setLoading(false)   
              }




    return (
        <div className="container-fluid">
        <div className="card-wrapper-tutorial">
            <div className="card">
                <div className="card-body">
                <h4 className="card-title">Edit Code Snippet</h4>
                    <form autoComplete="off">
                        <div className="form-group">
                            <label htmlFor="email">Title</label>
                            <input id="title" type="text" className="form-control"  placeholder="title" name="title"  onChange={handleChange} value={blogData.title || ""}  />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Blog Description</label>
                            <textarea id="blogdesc" rows={7} className="form-control"  placeholder="short description" name="blogdesc"  disabled = {disabled}  onChange={handleChange} value={blogData.blogdesc || ""}  />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Body</label>
                            <ReactQuill 
                                value={blogContent}
                                modules={EditCodeSnippet.modules}
                                formats={EditCodeSnippet.formats}
                                onChange={handleContentChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Category</label>
                            <select id="categoryid" className="form-control" name="category" onChange={handleChange}>
                             <option value={0}>Please Select One</option>
                                {categoryData.map(option => (
                                    <option value={option._id} >{option.title}</option>
                                )) }
                                 <option selected value={blogData.category._id}>{blogData.category.title}</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-block"  onClick={postBlog}>
                        {loading && <i className="fa fa-refresh fa-spin"></i>}
                        &nbsp;Submit</button>
                        </div>
                        <div className="form-group"  style={{color:"red",textAlign:"center",fontWeight:"bold"}}>
                            <span id="msg">{message}</span>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}

EditCodeSnippet.modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'],
        ['clean'],
        ['code-block']
    ]
};
 
EditCodeSnippet.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'indent',
    'bullet',
    'link',
    'image',
    'video',
    'code-block'
];


export default EditCodeSnippet
