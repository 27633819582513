import React,{useState,useEffect,Fragment} from 'react'
import {Link, Redirect } from "react-router-dom"
import moment from 'moment'
import Axios from 'axios'

const StudentWorkList = () => {
    const [studentWorkData, setStudentWorkData] = useState([])
    // const [categoryList, SetCategoryList] = useState({
    //     "categoryaccess":{}
    // })
    // const [studentEditData, setStudentEditData] = useState([])
    const [studentNewData, setStudentNewData] = useState("")
    // const [loading, setLoading] = useState(false)
    const lsData = !!localStorage.getItem("userData")

    useEffect(async() => {       
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + '/api/studentwork/viewstudentwork', {withCredentials:true})
        setStudentWorkData(response.data)    
    }, [])

    if(!lsData){
        return (<Redirect to='/signin' />) 
      }
  
    //   const selectEditData = async(id) =>{
    //       const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/student/getstudentid/${id}`, {withCredentials:true})
    //       setStudentEditData(response.data.student)         
    //   }
  
      const handleSearchChange = (e) =>{
          setStudentNewData({...studentNewData,[e.target.name]:e.target.value})
      }
 
      const SearchStudent = async() => {
          const response = await Axios.post(process.env.REACT_APP_FETCH_URL + '/api/student/findstudent', studentNewData, {withCredentials:true})
          setStudentWorkData(response.data)   
      }

  return (
    <Fragment>
    <div className="container-fluid"  style={{marginRight:"20px"}}>
            <div className="row" style={{marginRight:"20px"}}>
            <div  className="col-sm-6">
                <div className="input-group">
                    <input type="text" className="form-control" onChange={handleSearchChange} name="emailacct" />
                    <div className="input-group-append">
                    <button className="btn btn-secondary" onClick={SearchStudent} type="button"><i className="fa fa-search"></i></button>
                    </div>
                </div>
                </div>
                 <div className="col-sm-6">
                 <Link to="/addstudentwork" className="btn btn-primary" data-toggle="modal"  style={{float:"right"}}><i className="material-icons">&#xE147;</i><span> Add New Student Work</span></Link>
                </div>
                
            </div>
            <br/>
         <table className="table table-striped table-hover">
            <thead>
            <tr className="bg-info text-white">
                <th>Student Name</th>
                <th>Student Org</th>
                <th>Project Name</th>
                <th>Project Avatar</th>
                <th>Project URL</th>
                <th>Category</th>
                <th>Date Added</th>
                <th>Edit</th>
            </tr>
            </thead>
            <tbody>
             {studentWorkData.map(record => {
                return (<tr key={record._id}>
                 <td style={{width:"10%"}}>{record.studentname}</td>
                 <td style={{width:"10%"}}>{record.studentorganisation}</td>
                 <td style={{width:"10%"}}>{record.studentworkprojectname}</td>
                 <td style={{width:"10%"}}><img src={record.studentworkavatarurl} style={{width:"100px",height:"100px"}}/></td>
                 <td style={{width:"30%"}}>{record.studentworkurl}</td>
                 <td style={{width:"20%"}}>{record.courseid.coursetitle}</td>
                 <td style={{width:"10%"}}>{moment(record.date).format('MMM DD, YYYY')}</td>
                 <td style={{width:"5%"}}><Link to={`/editstudentwork/${record._id}`} className="edit"  data-toggle="modal">Edit</Link></td>
               </tr>
                )
             })                    
             }   
            </tbody>
        </table>
    </div>
    </Fragment>
  )
}

export default StudentWorkList