import React,{useState,useEffect} from 'react'
import {useHistory,useParams} from "react-router-dom";
import Axios from 'axios'
import moment from 'moment'
import DatePicker from  'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const AddSchedule = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [courseData, setCourseData] = useState("")
    const [scheduleData, setScheduleData] = useState([])
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingB, setLoadingB] = useState(false)
    const {id} = useParams()
    const history = useHistory()
   
    const ed = moment(endDate)
    const sd = moment(startDate + 1)
    
    useEffect(async() => {       
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/course/viewdetail/${id}`, {withCredentials:true})
        setCourseData(response.data)
    }, [])

    useEffect(async() => {       
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/schedule/viewschedule/${id}`, {withCredentials:true})
        setScheduleData(response.data)
    }, [])


    const postSchedule = async(e) =>{
        e.preventDefault()
        setMessage("")
        setLoading(true)
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/schedule/addschedule/${id}`, {startdate:startDate,enddate:endDate}, {withCredentials:true})
        setLoading(false)
        setMessage(response.data.msg)
        setScheduleData(response.data.scheduleresult)
        }
        
    const deleteSchedule = async(e,courseid) =>{
            e.preventDefault()
            setMessage("")
            setLoadingB(true)
            const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/schedule/deleteschedule/${id}`, {courseid}, {withCredentials:true})
            setLoadingB(false)
            setScheduleData(response.data)
          }

    return (
        <div className="container-fluid">
        <div className="card-wrapper-tutorial">
            <div className="card">
                <div className="card-body">
                <h4 className="card-title">Add New Schedule</h4>
                    <form autoComplete="off">
                        <div className="form-group">
                            <h5>{courseData.coursetitle}</h5>
                            <h6>{courseData.courseduration}</h6>
                        </div>
                        <div className="form-group">
                            <h6>Start Date</h6>
                            <DatePicker className="form-control" selected={startDate}
                            onChange={date => setStartDate(date)}
                            dateFormat='MMMM dd, yyyy' 
                            filterDate={date => date.getDay() !== 6 && date.getDay() !== 0}
                            minDate={new Date()}/>
                        </div>
                        <div className="form-group">
                            <h6>End Date</h6>
                            <DatePicker className="form-control" selected={endDate} 
                            onChange={date => setEndDate(date)}  
                            dateFormat='MMMM dd, yyyy' 
                            filterDate={date => date.getDay() !== 6 && date.getDay() !== 0}
                            minDate={startDate}/>
                        </div>
                        <div className="form-group">
                            <h6>Total Number of Days: {ed.diff(sd, 'days') + 1}</h6>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-block" onClick={postSchedule}>
                            {loading && <i className="fa fa-refresh fa-spin"></i>}
                            &nbsp;Submit</button>
                        </div>
                        <div className="form-group">
                            <h6 className="text-danger">{message}</h6>
                        </div>
                    </form>
                </div>
            </div>
                <ul class="list-group">
                    <h5>Course Schedules</h5>
                    {scheduleData.map((items) =>{
                        return(
                            <li key={items._id} className="list-group-item">{moment(items.startdate).format('MMMM DD, YYYY')} <span style={{marginRight:"0.2rem",marginLeft:"0.2rem"}}>to </span>{moment(items.enddate).format('MMMM DD, YYYY')} <span style={{float:"right"}}><button className="btn btn-primary" onClick={(event) =>deleteSchedule(event,items._id)}> {loadingB && <i className="fa fa-refresh fa-spin"></i>}
                            &nbsp;Delete</button></span></li>
                            )
                        }
                    )}
              </ul>
        </div>
       
    </div>
    )
}

export default AddSchedule
