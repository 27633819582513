import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import {useState} from 'react'
import {UserContext} from './components/userContext'
import Signin from './components/Signin';
import Signup from './components/Signup';
import Dashboard from './components/dashboard/Dashboard';
import AddTutorial from './components/dashboard/AddTutorial';
import Tutorial from './components/dashboard/Tutorial';
import EditTutorial from './components/dashboard/EditTutorial';
import CategoryList from './components/dashboard/CategoryList';
import StudentList from './components/dashboard/StudentList';
import StudentWorkList from './components/dashboard/StudentWorkList';
import AddStudentWork from './components/dashboard/AddStudentWork';
import EditStudentWork from "./components/dashboard/EditStudentWork";
import StudentAddCategory from './components/dashboard/StudentAddCategory';
import CodeSnippet from './components/dashboard/CodeSnippet';
import AddCodeSnippet from './components/dashboard/AddCodeSnippet';
import EditCodeSnippet from './components/dashboard/EditCodeSnippet';
import ViewCodeSnippet from './components/dashboard/ViewCodeSnippet';
import UploadCertificate from './components/dashboard/UploadCertificate';
import ViewCertificate from './components/dashboard/ViewCertificate';
import EditCertificate from './components/dashboard/EditCertificate';
import CourseList from './components/dashboard/CourseList';
import AddCourse from './components/dashboard/AddCourse';
import EditCourse from './components/dashboard/EditCourse';
import UploadCourseBrochure from './components/dashboard/UploadCourseBrochure';
import AddSchedule from './components/dashboard/AddSchedule';
import ViewFeedback from "./components/dashboard/ViewFeedback";
import AddFeedback from "./components/dashboard/AddFeedback";
import EditFeedback from "./components/dashboard/EditFeedback";
import ViewFullstack from "./components/dashboard/ViewFullstack";
import AddFullstack from "./components/dashboard/AddFullstack";
import EditFullstack from "./components/dashboard/EditFullstack";
import ViewClientPartner from "./components/dashboard/ViewClientPartner";
import AddClientPartner from "./components/dashboard/AddClientPartner";
import EditClientPartner from "./components/dashboard/EditClientPartner";
import BootstrapNavbar from './components/Navbar';
import './App.css';



function App() {
  const location = useLocation()
  const [displayname, setDisplayName] = useState("Guest")
  const [useremail, setUserEmail] = useState("")
  const [userId, setUserId] = useState("")
  const [accessToken, setAccessToken] = useState("")
  const [profileImage, setProfileImage] = useState("")



  const userDataContext = {
    displayname, 
    setDisplayName,
    useremail, 
    setUserEmail,
    userId, 
    setUserId,
    accessToken,
    setAccessToken,
    profileImage,
    setProfileImage
 }

  return (
    <div>
       <Switch> 
            <UserContext.Provider value={userDataContext}>
            <BootstrapNavbar/>      
            <Route exact path="/"
               render={() => {
                  return(            
                     localStorage.getItem("userData") === null ? <Redirect to='/signin'  />:<Redirect to='/dashboard' />
                  )
               }}>
            </Route>
            <Route exact path="/dashboard">
               <Dashboard/>
            </Route>
            <Route exact path="/signin">
               <Signin/>
            </Route>
            <Route exact path="/tutorial">
               <Tutorial/>
            </Route>
            <Route exact path="/addcodesnippet">
               <AddCodeSnippet/>
            </Route>
            <Route exact path="/viewblog/:id">
               <ViewCodeSnippet/>
            </Route>
            <Route exact path="/editblog/:id">
               <EditCodeSnippet/>
            </Route> 
            <Route exact path="/codesnippet">
               <CodeSnippet/>
            </Route>        
            <Route exact path="/addtutorial">
               <AddTutorial/>
            </Route>
            <Route exact path="/edittutorial/:id">
               <EditTutorial/>
            </Route>
            <Route exact path="/category">
               <CategoryList/>
            </Route>
            <Route exact path="/student">
               <StudentList/>
            </Route>
            <Route exact path="/studentworklist">
               <StudentWorkList/>
            </Route>
            <Route exact path="/addstudentwork">
               <AddStudentWork/>
            </Route>
            <Route exact path="/editstudentwork/:id">
               <EditStudentWork/>
            </Route>
            <Route exact path="/getstudentid/:id">
               <StudentAddCategory/>
            </Route>
            <Route exact path="/uploadcertificate/:id">
               <UploadCertificate/>
            </Route>
            <Route exact path="/viewcertificate/:id">
               <ViewCertificate/>
            </Route>
            <Route exact path="/editcertificate/:id">
               <EditCertificate/>
            </Route>
            <Route exact path="/courselist">
               <CourseList/>
            </Route>
            <Route exact path="/addcourse">
               <AddCourse/>
            </Route>
            <Route exact path="/editcourse/:id">
               <EditCourse/>
            </Route>
            <Route exact path="/uploadcoursebrochure/:id">
               <UploadCourseBrochure/>
            </Route>
            <Route exact path="/addschedule/:id">
               <AddSchedule/>
            </Route>
            <Route exact path="/viewfeedback">
               <ViewFeedback/>
            </Route>
            <Route exact path="/addfeedback">
               <AddFeedback/>
            </Route>
            <Route exact path="/editfeedback/:id">
               <EditFeedback/>
            </Route>
            <Route exact path="/fullstacklist">
               <ViewFullstack/>
            </Route>
            <Route exact path="/addfullstack">
               <AddFullstack/>
            </Route>
            <Route exact path="/editfullstack/:id">
               <EditFullstack/>
            </Route>
            <Route exact path="/clientpartnerlist">
               <ViewClientPartner/>
            </Route>
            <Route exact path="/addclientpartner">
               <AddClientPartner/>
            </Route>
            <Route exact path="/editclientpartner/:id">
               <EditClientPartner/>
            </Route>
            </UserContext.Provider>
         </Switch> 
    </div>
  );
}

export default App;
