import React, { useState, useEffect } from 'react'
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import Axios from 'axios'
import Linkify from 'react-linkify';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";

const EditCourse = () => {
    const [courseData, setCourseData] = useState("")
    const [imageData, setImageData] = useState([])
    const [uploadButtonData, setUploadButtonData] = useState("Upload Course Image")
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const [courseDescription, setCourseDescription] = useState("")
    const { id } = useParams()

    const { coursetitle, coursepagetitleseo, courseseodescription, coursemetakeyword, courseduration,courseamount, courseimagealt,coursevisibility } = courseData

    useEffect(async () => {
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/course/editcourse/${id}`, { withCredentials: true })
        setCourseData(response.data)
        setCourseDescription(response.data.coursedescription)
    }, [])

    const handleChange = (e) => {
        setImageData(e.target.files[0])
        setUploadButtonData(e.target.files[0].name)
    }

    const handleChangeInput = (e) => {
        setCourseData({ ...courseData, [e.target.name]: e.target.value })
    }

    const handleContentChange = (e) => {
        setCourseDescription(e)
    }

    const UploadCertificate = async (e) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("image", imageData);
        formData.append("coursetitle", coursetitle);
        formData.append("coursepagetitleseo", coursepagetitleseo);
        formData.append("courseseodescription", courseseodescription);
        formData.append("coursemetakeyword", coursemetakeyword);        
        formData.append("courseduration", courseduration);
        formData.append("courseamount", courseamount);
        formData.append("courseimagealt", courseimagealt);
        formData.append("coursevisibility", coursevisibility);
        formData.append("coursedescription", courseDescription);
        e.preventDefault()
        const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/course/saveeditcourse/${id}`, formData, { withCredentials: true })
        if (response.data.statusCode === "200") {
            setLoading(false)
            history.push('/courselist')
        }
    }

    return (
        <div className="container-fluid">
            <div className="card-wrapper-tutorial">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Edit Course</h4>
                        <form autoComplete="off">
                            <div className="form-group">
                                <label htmlFor="email">Course Title</label>
                                <input id="coursetitle" name="coursetitle" type="text" defaultValue={courseData.coursetitle || ""} onChange={handleChangeInput} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Course Page Title SEO</label>
                                <input id="coursepagetitleseo" name="coursepagetitleseo" type="text" defaultValue={courseData.coursepagetitleseo || ""} onChange={handleChangeInput} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Course SEO Description</label>
                                <input id="courseseodescription" name="courseseodescription" type="text" defaultValue={courseData.courseseodescription || ""} onChange={handleChangeInput} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Course Meta Keyword</label>
                                <input id="coursemetakeyword" name="coursemetakeyword" type="text" defaultValue={courseData.coursemetakeyword || ""}  onChange={handleChangeInput} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Course Duration</label>
                                <input id="courseduration" name="courseduration" type="text" maxLength="25" defaultValue={courseData.courseduration || ""} onChange={handleChangeInput} required className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Course Amount">Course Amount (SGD)</label>
                                <input type="number" name="courseamount" onChange={handleChangeInput} defaultValue={courseData.courseamount || ""} className="form-control" maxLength="100" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Course Image ALT</label>
                                <input id="courseimagealt" name="courseimagealt" type="text" defaultValue={courseData.courseimagealt || ""} onChange={handleChangeInput} required className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="coursevisibility">Course Visibility</label>
                                <input id="coursevisibility" name="coursevisibility" type="text" defaultValue={courseData.coursevisibility || ""} onChange={handleChangeInput} required className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Course Description</label>
                                <ReactQuill
                                    value={courseDescription}
                                    placeholder="Start writing something"
                                    modules={EditCourse.modules}
                                    formats={EditCourse.formats}
                                    onChange={handleContentChange} />
                            </div>
                            <div className="form-group">
                                <label for="files" className="btn btn-outline-secondary">{uploadButtonData}
                                    <input id="files" name="image" className="form-control" hidden accept="image/*" onChange={handleChange} type="file" /></label>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-block" onClick={UploadCertificate}>
                                    {loading && <i className="fa fa-refresh fa-spin"></i>}
                                    &nbsp;Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


EditCourse.modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        ['link', 'image', 'video'],
        ['clean'],
        ['code-block']
    ]
};

EditCourse.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'align',
    'indent',
    'bullet',
    'link',
    'image',
    'video',
    'code-block'
];

export default EditCourse
