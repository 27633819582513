import React,{useState,useEffect} from 'react'
import {useHistory,useParams } from "react-router-dom"
import Axios from 'axios'


const UploadCourseBrochure = () => {
    const [courseData, setCourseData] = useState("")
    const [imageData, setImageData] = useState([])
    const [uploadButtonData, setUploadButtonData] = useState("Upload Course Brochure")
    const [loading, setLoading] = useState(false)
    const {id} = useParams()
    const history = useHistory()

    useEffect(async() => {  
        const response = await Axios.get(process.env.REACT_APP_FETCH_URL + `/api/course/editcourse/${id}`, {withCredentials:true})
        setCourseData(response.data)  
     }, [])

    const handleChange = (e) =>{
        setImageData(e.target.files[0])
        setUploadButtonData(e.target.files[0].name)
   }

   const UploadCourseBrochure = async(e) => {
    setLoading(true)
    const formData = new FormData();
    formData.append("image", imageData);
    e.preventDefault()
    const response = await Axios.post(process.env.REACT_APP_FETCH_URL + `/api/course/uploadcoursebrochure/${id}`, formData, {withCredentials:true})
    if (response.data.statusCode === "200") {
        setLoading(false)
        history.push('/courselist')
    }    
}

    return (
        <div className="container-fluid">
        <div className="card-wrapper-tutorial">
            <div className="card">
                <div className="card-body">
                <h4 className="card-title">Upload Course Brochure</h4>
                    <form autoComplete="off">
                        <div className="form-group">
                            <label htmlFor="email">Course Title</label>
                            <label htmlFor="email" className="form-control">{courseData.coursetitle}</label> 
                        </div>   
                        <div className="form-group">
                        <label for="files" className="btn btn-outline-secondary">{uploadButtonData}
                        <input id="files" name="image" className="form-control" hidden accept="application/pdf" onChange={handleChange} type="file"/></label>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-block"  onClick={UploadCourseBrochure}>
                            {loading && <i className="fa fa-refresh fa-spin"></i>}
                            &nbsp;Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}

export default UploadCourseBrochure
